/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Breadcrumb} from 'react-bootstrap';

import * as AiIcons from 'react-icons/ai';
import StudentService from '../student/StudentService';
import CourseService from './CourseService';
import CategoryService from '../category/CategoryService';

const EditCourse = () => {
  const [title, setTitle] = useState('');
  const [shortname, setShortname] = useState('');
  const [courseIDNumber, setCourseIDNumber] = useState();
  const [status, setStatus] = useState(false);
  const [course_short_name, setCourse_short_name] = useState('');
  const [semester, setSemester] = useState();
  const [credit, setCredit] = useState();
  const [description, setDescription] = useState('');
  const [gradeid, setGradeid] = useState();
  const [gradeComponents, setGradeComponents] = useState([]);
  const [courseCategory, setCourseCategory] = useState();
  const [categories, setCategories] = useState([]);
  const [course_start_date, setStartDate] = useState('');
  const [courseStartTime, setCourseStartTime] = useState('');

  const navigate = useNavigate();
  const {id} = useParams();

  useEffect( () => {
    getCourseById();
    getGradeComponents();
    getCategories();
  },[]);


  const getCategories = async () => {
    let categories = await CategoryService.getAll();
    categories = categories.data.data
    setCategories(categories || []);
  }

  const getCourseById = async () => {
    let res = await CourseService.getCourseById(id);
    res = res.data.data

    setTitle(res.name)
    setShortname(res.short_name)
    setDescription(res.summary)
    setCourseIDNumber(res.course_id_number)
    setStatus(res.visible)
    setSemester(res.course_semester)
    setCredit(res.course_credit)
    setGradeid(res.grade_component_id)
    setCourseCategory(res.category_id)
    setStartDate(res.course_start_date)

    const courseStartDate = res.course_start_date;// Convert timestamp ke millisecond dan buat Date object
    const date = new Date(courseStartDate * 1000);
    
    
    // Menggunakan metode toLocaleString untuk mengonversi ke zona waktu Indonesia (WIB)
    const options = { timeZone: 'Asia/Jakarta', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit',  };
    const indonesiaTime = date.toLocaleString('id-ID', options);
    setCourseStartTime(indonesiaTime)

  }

  const getGradeComponents = async () => {
    let resComp = await StudentService.getGrade();
    resComp = resComp.data.data
    setGradeComponents(resComp );
  }

  const updateCourse = async (e) => {
    e.preventDefault();

    let payloadAddCourse = {
      fullname: title,
      shortname : course_short_name, 
      summary: description,
      idnumber: courseIDNumber,
      visible : status,
      course_semester : parseInt(semester, 10),
      course_credit : parseInt(credit, 10),
      
      // TODO:
      // course_start_date : formattedDate,
      // course_number_sections : parseInt(course_number_sections, 10) || 0,
      // course_format: course_format,
    }
    
    CourseService.putCourse(payloadAddCourse, id).then((resp) => {
      if (resp?.response?.status == 400){
        alert(resp.response.data.message)
      }

      if (resp?.response?.status == 500){
        alert(resp.response.data.message)
      }

      if (!resp.data.error) {
        alert("Successfully edited course!")
        navigate("/course");
      } 

    });  
  }

  return (
    <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/course'>Courses Data</Breadcrumb.Item>
          <Breadcrumb.Item>Edit Course</Breadcrumb.Item>
          <Breadcrumb.Item active>{id}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <form onSubmit={ updateCourse }>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Course Name</label>
          <div className='col-sm-5'><input 
            type="text"
            className="form-control"
            value={ title } 
            onChange={ (e) => setTitle(e.target.value) }
            placeholder="Course Name"
          />
          </div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Course Short Name</label>
          <div className='col-sm-5'><input 
            type="text"
            className="form-control"
            value={ shortname } 
            onChange={ (e) => setCourse_short_name(e.target.value) }
            placeholder="Course Short Name "
            required
          /></div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Course ID Number</label>
          <div className='col-sm-5'><input 
            type="text"
            className="form-control"
            value={ courseIDNumber } 
            onChange={ (e) => setCourseIDNumber(e.target.value) }
            placeholder="course id number "
            required
          /></div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Course Category</label>
          <div className='col-sm-5'>
            <select className='form-select' value={ courseCategory } required onChange={ (e) => setCourseCategory(parseInt(e.target.value, 10)) }>
              <option>-Select category-</option>
              {categories?.map((category, index) => {
                return (
                  <option key={index} value={category.mdl_category_id}>
                  {category.name}
                  </option>
                )
              })}              
            </select>
          </div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Semester</label>
          <div className='col-sm-5'>
          <select className='form-select'  value={ semester } required onChange={ (e) => setSemester(e.target.value) }>
            <option>-Select Semester-</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
            </select>
          </div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Course Credit</label>
          <div className='col-sm-5'>
          <select className='form-select'  value={ credit } required onChange={ (e) => setCredit(e.target.value) }>
            <option>-Select Course Credits (SKS)-</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="6">6</option>
              <option value="8">8</option>
              <option value="10">10</option>
            </select>
          </div>
        </div>

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Grade Component</label>
          <div className='col-sm-5'>
            <select className='form-select' value={ gradeid } required onChange={ (e) => setGradeid(parseInt(e.target.value, 10)) }>
              <option>-Select grade component-</option>
              {gradeComponents?.map((gc, index) => {
                return (
                  <option key={index} value={gc.id}>
                  {gc.name}
                  </option>
                )
              })}              
            </select>
          </div>
        </div>

        {/* <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Course Start Date</label>
          <div className='col-sm-5'>
            <input 
              type="datetime-local"
              className="form-control"
              value={course_start_date}
              onChange={(e) => setStartDate(e.target.value)}
              required
            />
          </div>
        </div> */}

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Description</label>
          <div className='col-sm-5'><textarea
            className="form-control"            
            placeholder="Course Description"
            value={description}
            onInput={ (e) => setDescription(e.target.value) }
          >          
          </textarea></div>
        </div>     

        <div className="row mb-3">
          <label className="col-sm-2 col-form-label">Status</label>
          <div className='col-sm-6'>
            <div className="form-check form-switch">
              <input 
                className="form-check-input" 
                type="checkbox" 
                id="statusSwitch" 
                checked={status} 
                onChange={() => setStatus(!status)}
              />
              <label className="form-check-label" htmlFor="statusSwitch">
                {status ? 'Active' : 'Inactive'}
              </label>
            </div>
          </div>
        </div>   

        <div className="field">
          <Link to={`/course`} className='btn btn-outline-danger btn-sm'><AiIcons.AiOutlineRollback size={18} />Cancel</Link>
          &nbsp;&nbsp;
          <Link title='Save' className='btn btn-outline-success btn-sm' onClick={updateCourse}><AiIcons.AiFillSave size={18} />Save</Link>
        </div>
      </form>
    </div>
  )
}

export default EditCourse