// custom css
import './css/sb-admin-2.min.css';
import './vendor/fontawesome-free/css/all.min.css';
import './vendor/datatables/dataTables.bootstrap4.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

//web layout
import Header from './layout/Header';
import { LayoutDashboard,Layout404, Page404} from './layout/layout';

import Homepage from './pages/Homepage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// student
import StudentList from './pages/student/StudentList';
import AddStudent from './pages/student/AddStudent';
import {ViewStudent} from './pages/student/ViewStudent';
import EditStudent from './pages/student/EditStudent';

// lecturer
import LecturerList from './pages/lecturer/LecturerList';
import AddLecturer from './pages/lecturer/AddLecturer';
import ViewLecturer from './pages/lecturer/ViewLecturer';
import EditLecturer from './pages/lecturer/EditLecturer';

// manager/tendik
import ManagerList from './pages/lecturer/ManagerList';
import ViewManager from './pages/lecturer/ViewManager';
import AddManager from './pages/lecturer/AddManager';

// course
import CourseList from './pages/course/CourseList';
import ViewCourse from './pages/course/ViewCourse';
import EditCourse from './pages/course/EditCourse';
import AddCourse from './pages/course/AddCourse';

// studyprogram
import {StudyProgramList} from './pages/student/StudyProgramList';

// [TODO] : learning period
import LearningperiodList from './pages/learningperiod/LearningperiodList';
import AddLearningperiod from './pages/learningperiod/AddLearningperiod';
import EditLearningperiod from './pages/learningperiod/EditLearningperiod';

// offered course
import AddOfferedcourse from './pages/offeredcourse/AddOfferedcourse';
import OfferedcourseList from './pages/offeredcourse/OfferedcourseList';
import EditOfferedcourse from './pages/offeredcourse/EditOfferedcourse';

// enrollment - class management
import {CourseEnrollmentList} from './pages/enrollment/courseenrollmentList';
import {EnrollmentListV2} from './pages/enrollment/enrollmentList_v2';
// import ViewEnrollment from './pages/enrollment/ViewEnrollment';

// attendance
import {CourseAttendanceList} from './pages/attendance/courseattendanceList';
import {AttendanceList} from './pages/attendance/attendanceList';
import {AttendanceDetail} from './pages/attendance/attendanceDetail';

// grading 
import {CourseGradeList} from './pages/grade/coursegradeList';
import {GradeList} from './pages/grade/gradeList';
import {GradeDetail} from './pages/grade/gradeDetail';

// master data - grade component
import GradeComponentsList from './pages/student/GradeComponent';
import AddGradeComponent from './pages/student/AddGradeComponent';
import ViewGradeComponent from './pages/student/ViewGradeComponent';
import EditGradeComponent from './pages/student/EditGradeComponent';

// master data - category
import CategoryList from './pages/student/CategoryList';
import ViewCategory from './pages/student/ViewCategory';
import EditCategory from './pages/student/EditCategory';

// master data - competency (CPL)
import {CplList} from './pages/competency/CplList';
import {AddCPL} from './pages/competency/AddCpl';
import {EditCPL} from './pages/competency/EditCpl';

// master data - competency (CPMK)
import {CPMKbyCPLID} from './pages/competency/CPMKbyCPLid';
import {EditCPMK} from './pages/competency/EditCpmk';
import {AddCPMK} from './pages/competency/AddCPMK';

import {FormLogin} from './pages/auth/login';
import {Dashboard} from './pages/dashboard/dashboard';

const App = () => {
  return (
        <div id="content">
          <Header />
          <BrowserRouter>
            <Routes>
              <Route path='/login' element={<FormLogin/>} />
              <Route path='/dashboard-sso' element={<Dashboard/>} />

              <Route path='/' element={
                <LayoutDashboard>
                  <Homepage />
                </LayoutDashboard>
              } />
              
              {/* student */}
              <Route path='/student' element={
                <LayoutDashboard>
                  <StudentList />
                </LayoutDashboard>
              } />
              <Route path='/student/:id' element={
                <LayoutDashboard>
                  <ViewStudent/>
                </LayoutDashboard>
              } />
              <Route path='/student/edit/:id' element={
                <LayoutDashboard>
                  <EditStudent/>
                </LayoutDashboard>
              } />
              <Route path='/student/add' element={
                <LayoutDashboard>
                  <AddStudent />
                </LayoutDashboard>
              } />

              {/* lecturer */}
              <Route path='/lecturer' element={
                <LayoutDashboard>
                  <LecturerList />
                </LayoutDashboard>
              } />
              <Route path='/lecturer/:id' element={
                <LayoutDashboard>
                <ViewLecturer/>
              </LayoutDashboard>
              } />
              <Route path='/lecturer/edit/:id' element={
                 <LayoutDashboard>
                 <EditLecturer />
               </LayoutDashboard>
              } />
              <Route path='/lecturer/add' element={
                <LayoutDashboard>
                <AddLecturer />
              </LayoutDashboard>
              } />

              {/* manager */}
              <Route path='/manager' element={
                <LayoutDashboard>
                <ManagerList />
              </LayoutDashboard>
                } />
              <Route path='/manager/:id' element={
                  <LayoutDashboard>
                  <ViewManager/>
                </LayoutDashboard>
                
                } />
              <Route path='/manager/add' element={
                <LayoutDashboard>
                <AddManager />
              </LayoutDashboard>
              } />
              {/* <Route path='/manager/edit/:id' element={<EditLecturer />} /> */}
            
              <Route path='/course' element={
                <LayoutDashboard>
                <CourseList />
              </LayoutDashboard>
              } />
              <Route path='/course/:id' element={
                <LayoutDashboard>
                <ViewCourse/>
              </LayoutDashboard>
                } />
              <Route path='/course/edit/:id' element={
                <LayoutDashboard>
                  <EditCourse/>
              </LayoutDashboard>
                } />
              <Route path='/course/add' element={
                <LayoutDashboard>
                <AddCourse />
              </LayoutDashboard>
              } />

              {/* course catalogue */}
              <Route path='/course-catalog' element={
                <LayoutDashboard>
                <CourseList />
              </LayoutDashboard>
              } />

              {/* TODO : learning period */}
              <Route path='/learningperiod' element={<LearningperiodList />} />
              <Route path='/learningperiod/add' element={<AddLearningperiod />} />
              <Route path='/learningperiod/edit/:id' element={<EditLearningperiod/>} />

              <Route path='/registlecturercourse' element={
                <LayoutDashboard>
                  <OfferedcourseList />
                </LayoutDashboard>
              } />
              <Route path='/offeredcourse/add' element={
                <LayoutDashboard>
                  <AddOfferedcourse />
                </LayoutDashboard>
                } />
              <Route path='/offeredcourse/edit/:id' element={<EditOfferedcourse />} />

              <Route path='/enrollment/student' element={
                <LayoutDashboard>
                  <CourseEnrollmentList />
                </LayoutDashboard>
              } />

              <Route path='/enrollment/:courseid' element={
                <LayoutDashboard>
                  <EnrollmentListV2 />
                </LayoutDashboard>
              } />

              {/* attendance */}
              <Route path='/attendancecourse' element={
                <LayoutDashboard>
                <CourseAttendanceList />
              </LayoutDashboard>
              } />

              <Route path='/attendancecourse/:courseid' element={
                <LayoutDashboard>
                <AttendanceList />
              </LayoutDashboard>
              } />

              <Route path='/attendancecourse/:courseid/:session_id' element={
                <LayoutDashboard>
                  <AttendanceDetail />
                </LayoutDashboard>
              } />

              {/* Grading */}
              <Route path='/grade' element={
                <LayoutDashboard>
                <CourseGradeList />
              </LayoutDashboard>
              } />

              <Route path='/grade/:id' element={
                <LayoutDashboard>
                  <GradeList />
                </LayoutDashboard>
              } />

              <Route path='/grade/:courseid/:userid' element={
                <LayoutDashboard>
                  <GradeDetail />
                </LayoutDashboard>
              } />

              {/* MASTER DATA */}
              {/* grade components */}
              <Route path='/masterdata/gradecomponents' element={
                <LayoutDashboard>
                  <GradeComponentsList />
                </LayoutDashboard>
              } />
              <Route path='/gradecomponents/:id' element={
                <LayoutDashboard>
                  <ViewGradeComponent/>
                </LayoutDashboard>
              } />
              <Route path='/gradecomponents/add' element={
                <LayoutDashboard>
                  <AddGradeComponent />
                </LayoutDashboard>
                } />
              <Route path='/gradecomponents/edit/:id' element={
                <LayoutDashboard>
                  <EditGradeComponent/>
                </LayoutDashboard>
              } />

              {/* category */}
              <Route path='/masterdata/category' element={
                <LayoutDashboard>
                <CategoryList />
              </LayoutDashboard>
              } />
              <Route path='/master-category/:id' element={
                <LayoutDashboard>
                <ViewCategory/>
              </LayoutDashboard>
              } />
              <Route path='/master-category/edit/:id' element={
                 <LayoutDashboard>
                 <EditCategory/>
               </LayoutDashboard>
              } />

              {/* program study */}
              <Route path='/masterdata/studyprogram' element={
                <LayoutDashboard>
                  <StudyProgramList />
              </LayoutDashboard>
              } />

              {/* Competency */}
              <Route path='/masterdata/competency/cpl' element={
                <LayoutDashboard>
                  <CplList />
              </LayoutDashboard>
              } />

              <Route path='/masterdata/competency/cpl/add/:cpl_id' element={
                <LayoutDashboard>
                  <AddCPL />
              </LayoutDashboard>
              } />

              <Route path='/masterdata/competency/cpl/edit/:id' element={
                 <LayoutDashboard>
                 <EditCPL/>
               </LayoutDashboard>
              } />

            <Route path='/masterdata/competency/cpl/:id' element={
                <LayoutDashboard>
                <ViewCategory/>
              </LayoutDashboard>
              } />

            <Route path='/masterdata/competency/cpmk/:cpl_id' element={
                <LayoutDashboard>
                  <CPMKbyCPLID />
              </LayoutDashboard>
            } />

            <Route path='/masterdata/competency/cpmk/add/:cpl_id' element={
                <LayoutDashboard>
                  <AddCPMK />
              </LayoutDashboard>
              } />

            <Route path='/masterdata/competency/cpmk/edit/:cpmk_id/:cpl_id' element={
                 <LayoutDashboard>
                 <EditCPMK/>
               </LayoutDashboard>
              } />


            {/* handling not found page */}
            <Route path="*" element={
              <Layout404>
                <Page404/>
              </Layout404>
            }/>

            </Routes>
          </BrowserRouter>
        </div>
  );
}

export default App;
