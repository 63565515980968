// import React from 'react'
import './style.css'
import '../css/style.css';

import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Cookies from "js-cookie";
import { removeCookiesData } from '../utils/utils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Untuk mendapatkan path aktif saat ini
  const [activeMenu, setActiveMenu] = useState(''); // State untuk menu aktif
  
  useEffect(() => {
    const path = location.pathname;
    switch (true) {
      case path === '/':
        setActiveMenu('home');
        break;
      case path.startsWith('/student'):
        setActiveMenu('student');
        break;
      case path.startsWith('/lecturer'):
        setActiveMenu('lecturer');
        break;
      case path.startsWith('/manager'):
        setActiveMenu('manager');
        break;
      case path.startsWith('/masterdata/gradecomponents'):
        setActiveMenu('gradecomponent');
        break;
      case path.startsWith('/course'):
        setActiveMenu('course');
        break;
      case path.startsWith('/registlecturercourse'):
          setActiveMenu('lecturercourse');
          break;
      case path.startsWith('/enrollment/student'):
        setActiveMenu('enrolstudent');
        break;
      case path.includes('/attendancecourse'):
        setActiveMenu('courseattendance');
        break;
      case path.startsWith('/grade'):
        setActiveMenu('grading');
        break;
      case path.startsWith('/masterdata/category'):
        setActiveMenu('coursecategory');
        break;
      case path.startsWith('/masterdata/studyprogram'):
          setActiveMenu('studyprogram');
          break;
      case path.startsWith('/masterdata/competency/cpl'):
          setActiveMenu('competency');
          break;
      default:
        setActiveMenu('');
        break;
    }
  }, [location]);
  
  const handleLogout = () => {
    toast.warn("Logging out..");
    removeCookiesData();
    setTimeout(() => navigate("/login"), 2000);
  };

  return (
    <div className="d-flex flex-column h-100">
      <div className="">
        <button
          className="btn btn-danger w-100"
          onClick={handleLogout}
          style={{ bottom: '200px', left: '1px', width: '20px' }}
        >
          Logout
        </button>
    <ul className="navbar-nav bg-custom-blue sidebar sidebar-dark accordion" id="accordionSidebar">
      
      <li className="sidebar-brand align-items-center justify-content-center">
        <div className="sidebar-brand-icon rotate-n-0">
          <i className="fas fa-clipboard-list"></i>
        </div>
        <div className="sidebar-brand-text mx-3">SLS</div>
        
      </li>
      <li><div className="sidebar-heading"><span>Welcome {Cookies.get('username')} !</span></div></li>
          
      <li><hr className="sidebar-divider" /></li>      

      <li className="nav-item">
        <Link 
          className={`nav-link ${activeMenu === 'home' ? 'active' : ''}`} 
          to="/"
        >
          <i className="fas fa-fw fa-home"></i><span>HOME</span>
        </Link>
      </li>

      {/* <li className="nav-item ">
          <a className="nav-link" target="_blank"  href="https://sixukts.scrglab.id/"><i className="fas fa-server"></i><span>SIX</span></a>
      </li> */}

      {/* <li className="nav-item ">
          <a className="nav-link" target="_blank" href="https://smartcampus.my.id/signin"><i className="fas fa-clipboard"></i><span>LMS</span></a>
      </li> */}
      <li><hr className="sidebar-divider" /></li>

      {/* USERS */}
      <li>
        <div className="sidebar-heading">
          <i className="fas fa-fw fa-users"></i>
          <span> USERS MANAGEMENT</span>
        </div>
      </li>
      
      <li className="nav-item">
        <Link 
          className={`nav-link ${activeMenu === 'student' ? 'active' : ''}`} 
          to="/student"
        >
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-fw fa-user"></i><span>Students</span>
        </Link>
      </li>

      <li className="nav-item">
        <Link 
          className={`nav-link ${activeMenu === 'lecturer' ? 'active' : ''}`} 
          to="/lecturer"
        >
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-fw fa-user"></i><span>Lecturers</span>
        </Link>
      </li>

      <li className="nav-item">
        <Link 
          className={`nav-link ${activeMenu === 'manager' ? 'active' : ''}`} 
          to="/manager"
        >
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-fw fa-user-edit"></i><span>Managers</span>
        </Link>
      </li>

      <li>
        <hr className="sidebar-divider" />
      </li>

      {/* course mgt */}
      <li><div className="sidebar-heading"><i className="fas fa-fw fa-book"></i><span> course management</span></div></li>
      
      <li className="nav-item">
        <Link 
          className={`nav-link ${activeMenu === 'course' ? 'active' : ''}`} 
          to="/course"
        >
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-fw fa-book"></i><span>Course Catalogues</span>
        </Link>
      </li>

      <li className="nav-item">
        <Link 
          className={`nav-link ${activeMenu === 'course' ? 'active' : ''}`} 
          to="/course"
        >
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-fw fa-book"></i><span>Offered Courses</span>
        </Link>
      </li>

      <li className="nav-item">
        <Link 
          className={`nav-link ${activeMenu === 'lecturercourse' ? 'active' : ''}`} 
          to="/registlecturercourse"
        >
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-fw fa-book-open"></i><span>Course Lecturers</span>
        </Link>
      </li>


      {/* <li className="nav-item">
        <a className="nav-link" href="/learningperiod">
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-fw fa-calendar-alt"></i><span>Learning Period</span>
        </a>
      </li> */}

      <li><hr className="sidebar-divider" /></li>

      {/* learning activities */}
      <li><div className="sidebar-heading"><i className="fas fa-fw fa-person-booth"></i><span> learning activities</span></div></li>

      <li className="nav-item">
        <Link 
          className={`nav-link ${activeMenu === 'enrolstudent' ? 'active' : ''}`} 
          to="/enrollment/student"
        >
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-fw fa-user-check"></i><span>Enrolled Students</span>
        </Link>
      </li>

      <li className="nav-item">
        <Link 
          className={`nav-link ${activeMenu === 'courseattendance' ? 'active' : ''}`} 
          to="/attendancecourse"
        >
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-fw fa-check-square"></i><span>Course Attendance</span>
        </Link>
      </li>
      
      <li><hr className="sidebar-divider" /></li>
      {/* learning activities */}
      <li><div className="sidebar-heading"><i className="fas fa-star"></i><span> Grading</span></div></li>
      {/* <li className="nav-item">
        <a className="nav-link" href="/grade">
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-check-circle"></i><span>Grade</span>
        </a>
      </li> */}

      <li className="nav-item">
        <Link 
          className={`nav-link ${activeMenu === 'grading' ? 'active' : ''}`} 
          to="/grade"
        >
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-percentage"></i><span>Grade</span>
        </Link>
      </li>

      <li><hr className="sidebar-divider" /></li>
      {/* master data */}
      <li><div className="sidebar-heading"><i className="fas fa-database"></i><span> Master data</span></div></li>
      
      <li className="nav-item">
        <Link 
          className={`nav-link ${activeMenu === 'studyprogram' ? 'active' : ''}`} 
          to="/masterdata/studyprogram"
        >
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-university"></i><span>Study Program</span>
        </Link>
      </li>

      <li className="nav-item">
        <Link 
          className={`nav-link ${activeMenu === 'coursecategory' ? 'active' : ''}`} 
          to="/masterdata/category"
        >
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-book-open"></i><span>Course Category</span>
        </Link>
      </li>

      <li className="nav-item">
        <Link 
          className={`nav-link ${activeMenu === 'competency' ? 'active' : ''}`} 
          to="/masterdata/competency/cpl"
        >
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-trophy"></i><span>Competency</span>
        </Link>
      </li>

      <li className="nav-item">
        <Link 
          className={`nav-link ${activeMenu === 'gradecomponent' ? 'active' : ''}`} 
          to="/masterdata/gradecomponents"
        >
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span><i className="fas fa-check-circle"></i><span>Grade Components</span>
        </Link>
      </li>

    </ul>
    </div>
    <ToastContainer />
    </div>
  )
}
