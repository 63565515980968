/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { Breadcrumb} from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import StudentService from "./StudentService";

import * as AiIcons from 'react-icons/ai';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const ViewStudent = () => {
  const {id} = useParams();
  const [data, setData] = useState('');

  useEffect( () => {
    getStudentById();
  },[]);

  const getStudentById = async () => {
    let res = await StudentService.getStudentById(id);
    res = res.data.data
    setData(res)
  }  

  const full_name = data.firstname + " "+ data.middlename+" "+data.lastname;
  const department = data.degree+"-"+ data.study_program;

  const navigate = useNavigate();
  const deleteStudent = async (id) => {
    await StudentService.deleteStudent(id);
    alert("Succesfully delete student id:", id)
    navigate('/student');
  }

  return (
    <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/student'>Students Data</Breadcrumb.Item>
          <Breadcrumb.Item active>Student Detail</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Container>
      <Row> 
        <Col xs={6}>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Student ID (NIM) :</b></Col>
            <Col xs={8}>{data.username}</Col>
          </Row>
          <br/>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Full Name :</b></Col>
            <Col xs={8}>{full_name}</Col>
          </Row>
          <br/>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Affiliation :</b></Col>
            <Col xs={8}>{data.affiliation}</Col>
          </Row>
          <br/>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Department/Study Program :</b></Col>
            <Col xs={8}>{department}</Col>
          </Row>
          <br/>
        </Col>

        <Col xs={6}>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Email Address :</b></Col>
            <Col xs={8}>{data.email}</Col>
          </Row>
          <br/>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Phone Number :</b></Col>
            <Col xs={8}>{data.phone_number}</Col>
          </Row>
        </Col>
      </Row>
        
        <Row><Col>&nbsp;</Col></Row>
        <Row className="d-flex justify-content-center">
          <Col xs="auto">
            <Link to={`/student`} className='btn btn-outline-dark btn-sm'><AiIcons.AiOutlineRollback size={18} />Back</Link>
            &nbsp;&nbsp;
            <Link to={`/student/edit/`+id} className='btn btn-outline-primary btn-sm'><AiIcons.AiFillEdit size={18} />Edit</Link>
            &nbsp;&nbsp;
            <Link title='Delete' className='btn btn-outline-danger btn-sm' onClick={() => deleteStudent(id)}><AiIcons.AiTwotoneDelete size={18} />Delete</Link>            
          </Col>
        </Row>  
      </Container>
    </div>
  )
}
