import HttpCommon from "../../HttpCommon";
import HttpProdi from "../../HttpSmartProdi";

class LecturerService {
    getAll() {
        try {
            return HttpProdi.get("/lecturer");
        } catch (error) {
            console.log(error)
            return error
        }
    }

    getLecturerById (lecturerId) {
        try {
            return HttpProdi.get("/lecturer/"+lecturerId);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    addLecturer = async(lecturer) => {
        try {
            const lecturerArray = [lecturer];
            return await HttpProdi.post("/lecturer",lecturerArray);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    putLecturer(lecturer, lecturerId) {
        return HttpCommon.put("/lecturer/"+lecturerId, lecturer);
    }

    deleteLecturer(lecturerId) {
        return HttpCommon.delete("/lecturer/"+lecturerId);
    }

    // manager
    getAllManager() {
        try {
            return HttpProdi.get("/manager");
        } catch (error) {
            console.log(error)
            return error
        }
    }

    getManagerById (lecturerId) {
        try {
            return HttpProdi.get("/manager/"+lecturerId);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    addManager = async(lecturer) => {
        try {
            const lecturerArray = [lecturer];
            return await HttpProdi.post("/manager",lecturerArray);
        } catch (error) {
            console.log(error)
            return error
        }
    }
}

export default new LecturerService();