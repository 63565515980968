/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import StudentService from './StudentService';

import * as AiIcons from 'react-icons/ai';
import * as BiIcons from 'react-icons/bi';

const GradeComponentsList = () => {
  const [students, setStudents] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  
  useEffect( () => {
    getStudents();
  },[]);

  const getStudents = async () => {
    const students = await StudentService.getGrade();
    setStudents(students.data.data || []);
  }

  const deleteComponent = async (id) => {
    await StudentService.deleteGradeComponent(id);
    getStudents();
  }

  const handleDeleteClick = (id) => {
    setSelectedCourseId(id);
    setShowConfirm(true);
  };

  const handleConfirmDelete = () => {
    if (selectedCourseId) {
      deleteComponent(selectedCourseId);
    }
    setShowConfirm(false);
  };

  const handleCancelDelete = () => {
    setShowConfirm(false);
    setSelectedCourseId(null);
  };

  // inline style css
  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  const modalContentStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '4px',
    width: '300px',
    textAlign: 'center',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
  };

  if (students.length === 0) {
    return(
      <div className='container-fluid'>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Grade Components</h1>
          <a href="" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
            <i className="fas fa-fw fa-user"></i> <span>Add New Grade Component</span>
          </a>
        </div>
        <div><p className='fs-6 fw-semibold'>The grade component data is empty. Please add some new grade component !!</p></div>
      </div>
    )    
  } 

  return(
    <div className='container-fluid'>
      {/* Subsection */}
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Grade Components Data</h1>
        <a href="/gradecomponents/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
          <i className="fas fa-fw fa-user"></i> <span>Add New Grade Component</span>
        </a>
      </div>

      <table className='table table-sm'>
        <thead>
          <tr>
          <th>
            ID
          </th>
          <th>
            Name
          </th>
          <th >
            Components
          </th>
          <th>Active</th>            
          <th>Actions</th>
          </tr>
        </thead>
        <tbody className='table-group-divider'>
          { students.map((student, index) => (
              <tr key={student.id}>
                  <td>{student.id}</td>
                  <td>{student.name}</td>
                  {/* <td>{student.component}</td> */}
                  <td>
                    {/* Iterate over component object */}
                    {Object.entries(student.component).map(([key, value]) => (
                      <div key={key}>
                        {key}: {value}
                      </div>
                    ))}
                  </td>
                  <td>
                    {student.is_active ? (
                      <AiIcons.AiOutlineCheck size={20} color="green" />
                    ) : (
                      <AiIcons.AiOutlineClose size={20} color="red" />
                    )}
                  </td>
                  <td>
                      <Link title='Detail' to={`/gradecomponents/`+student.id}>
                        <BiIcons.BiDetail size={18} />
                      </Link>
                      &nbsp;&nbsp;&nbsp;
                      <Link title='Edit' to={`/gradecomponents/edit/`+student.id}>
                        <AiIcons.AiFillEdit size={18} />
                      </Link>&nbsp;&nbsp;&nbsp;

                      <Link title='Delete' className='btn btn-outline-danger btn-sm' onClick={() => handleDeleteClick(student.id)}>
                      <AiIcons.AiTwotoneDelete size={18} /></Link>
                  </td>
              </tr>
          )) }
        </tbody>
      </table>
      {/* Modal konfirmasi */}
        {showConfirm && (
          <div style={modalStyle}>
            <div style={modalContentStyle}>
              <p>Are you sure you want to delete this course?</p>
              <button className="btn btn-danger" onClick={handleConfirmDelete}>Confirm</button>&nbsp;&nbsp;
              <button className="btn btn-secondary" onClick={handleCancelDelete}>Cancel</button>
            </div>
          </div>
        )}
    </div>      
  )
}

export default GradeComponentsList