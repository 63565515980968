/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import StudentService from './StudentService';

import * as AiIcons from 'react-icons/ai';
import * as BiIcons from 'react-icons/bi';
import { Box, Typography } from '@mui/material';
import {  Spinner } from 'react-bootstrap';

const StudentList = () => {
  const [students, setStudents] = useState([]);
  const [totalStudent, setTotalStudent] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortOrderAff, setSortOrderAffiliation] = useState('asc');
  const itemsPerPage = 15; // Jumlah item per halaman

  useEffect( () => {
    getStudents();
  },[]);

  const getStudents = async () => {
    let students = await StudentService.getAll();
    students = students.data.data
    setStudents(students || []);
    setTotalStudent(students.length)
  }

  // TODO:
  const deleteStudent = async (id) => {
    await StudentService.deleteStudent(id);
    getStudents();
  }

  const sortByNim = () => {
    const sortedStudents = [...students].sort((a, b) => {
      if (sortOrder === 'asc') {
        return a.username.localeCompare(b.username);
      } 
      return b.username.localeCompare(a.username);
    });
    setStudents(sortedStudents);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'); // Toggle urutan sortir
  };

  const sortByAffiliation = () => {
    const sortedStudentsAff = [...students].sort((a, b) => {
      if (sortOrderAff === 'asc') {
        return a.affiliation.localeCompare(b.affiliation);
      } 
      return b.affiliation.localeCompare(a.affiliation);
    });
    setStudents(sortedStudentsAff);
    setSortOrderAffiliation(sortOrderAff === 'asc' ? 'desc' : 'asc'); // Toggle urutan sortir
  };

  // Filter data siswa berdasarkan pencarian
  const filteredStudents = students.filter((student) => {
    return (
      student.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.affiliation.toLowerCase().includes(searchTerm.toLowerCase()) || 
      student.study_program.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  // Hitung total halaman
  const totalPages = Math.ceil(filteredStudents.length / itemsPerPage);

  // Ambil data yang sesuai dengan halaman saat ini
  const currentStudents = filteredStudents.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Fungsi untuk pindah halaman
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (students.length === 0) {
    return(
      <div className='container-fluid'>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Student Data</h1>
          <a href="/student/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
            <i className="fas fa-fw fa-user"></i> <span>Add New Student</span>
          </a>
        </div>
        <div className="text-center my-5">
          {/* Spinner animasi untuk menampilkan loading */}
          <Spinner animation="border" role="status" className="mb-3">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p className='fs-6 fw-semibold'>if reload takes too long please go back, data is either empty or still in processing</p>
        </div>
      </div>
    )    
  } 

  return(
    <div className='container-fluid'>
      {}
      {/* Subsection */}
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Student Data</h1>
        
        <a href="/student/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
          <i className="fas fa-fw fa-user"></i> <span>Add New Student</span>
        </a>
      </div>
      {/* total student */}
      <Box 
          sx={{
              backgroundColor: '#f0f8ff', 
              borderRadius: 2, 
              padding: 2, 
              textAlign: 'center',
              boxShadow: 1
          }}
      >
          <Typography variant="h6" color="primary">
              Total Students
          </Typography>
          <Typography variant="h4" fontWeight="bold" color="secondary">
              {totalStudent}
          </Typography>
      </Box>

      {/* Input Pencarian */}
      <div className='mb-3'>
          <input
            type='text'
            className='form-control'
            placeholder='Search students...'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
      </div>

      <table className='table table-sm'>
        <thead>
          <tr>
          <th>
           No
          </th>
          <th onClick={sortByNim} style={{ cursor: 'pointer' }}>
            Student ID (NIM) {sortOrder === 'asc' ? <AiIcons.AiOutlineArrowUp /> : <AiIcons.AiOutlineArrowDown />}
          </th>
          <th>
            Full Name
          </th>
          <th onClick={sortByAffiliation} style={{ cursor: 'pointer' }}>
            Affiliation {sortOrderAff === 'asc' ? <AiIcons.AiOutlineArrowUp /> : <AiIcons.AiOutlineArrowDown />}
          </th>
          <th>Degree</th>     
          <th>Study Program</th>              
          <th>Email</th>
          <th>Actions</th>
          </tr>
        </thead>

        <tbody className='table-group-divider'>
          { currentStudents.map((student, index) => (
              <tr key={student.mdl_id}>
                <td>{index+1}</td>
                  <td>{student.username}</td>
                  <td>{`${student.firstname} ${student.middlename} ${student.lastname}`}</td>
                  <td>{student.affiliation}</td>
                  <td>{student.degree}</td>
                  <td>{student.study_program}</td>
                  <td>{student.email}</td>
                  {/* TODO: */}
                  <td>
                      <Link title='Detail' to={`/student/`+student.mdl_id}>
                        <BiIcons.BiDetail size={18} />
                      </Link>
                      &nbsp;&nbsp;&nbsp;
                      <Link title='Edit' to={`/student/edit/`+student.mdl_id}>
                        <AiIcons.AiFillEdit size={18} />
                      </Link>&nbsp;&nbsp;&nbsp;

                      {/* <Link title='Delete' onClick={()=>deleteStudent(student.id)}><AiIcons.AiTwotoneDelete size={18} /></Link> */}
                  </td>
              </tr>
          )) }
        </tbody>

      </table>
      {/* Pagination Controls */}
      <nav>
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
            Previous
          </button>
        </li>
        {[...Array(totalPages).keys()].map((number) => (
          <li key={number + 1} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(number + 1)}>
              {number + 1}
            </button>
          </li>
        ))}
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
            Next
          </button>
        </li>
      </ul>
    </nav>
    </div>      
  )
}

export default StudentList