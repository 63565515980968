import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import './Dashboard.css';

export const Dashboard = () => {
  const navigate = useNavigate();
  const [redirectedLMS, setRedirectedLMS] = useState('');

  let username =  Cookies.get('username')
  useEffect(() => {
    if (!username) {
        alert("Tolong login terlebih dahulu!")
        navigate('/login'); // Redirect ke halaman "/"
    }
}, [navigate]); 

  // Mendapatkan URL redirect LMS dari cookies
  useEffect(() => {
    const lmsUrl = Cookies.get('redirected_mdl_url');
    setRedirectedLMS(lmsUrl);
  }, []);

  const handleRedirect = (url) => {
    // Redirect ke URL yang ditentukan
    window.location.href = url;
  };

  return (
    <div className="dashboard-container">
      <h1>Welcome back, {username} !</h1>
      {/* <p>You are not enrolled in any courses. Please contact your academic administrator.</p> */}
      
      <div className="role-card-container">
        <div className="role-card">
          {/* <img src="path-to-your-lms-admin-image" alt="Learning Management System" className="role-image" /> */}
          <h3>LMS Dashboard</h3>
          <p>Proceed to experience virtual learning management systems</p>
          <button
            onClick={() => handleRedirect(redirectedLMS)}
            className="btn btn-primary"
          >
            Go to LMS
          </button>
        </div>
        
        <div className="role-card">
          {/* <img src="" alt="Smart Learning System" className="role-image" /> */}
          <h3>SLS Dashboard</h3>
          <p>Proceed to head to the system for monitoring master data</p>
          <button
            onClick={() => handleRedirect('/')}
            className="btn btn-primary"
          >
            Go to SLS
          </button>
        </div>
      </div>
    </div>
  );
}
