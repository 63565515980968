/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import LecturerService from './LecturerService';

import * as AiIcons from 'react-icons/ai';
import * as BiIcons from 'react-icons/bi';
import { Box, Typography } from '@mui/material';
import {  Spinner } from 'react-bootstrap';

const LecturerList = () => {
  const [lecturers, setLecturers] = useState([]);
  const [totalLecturer, setTotalLecturer] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrderAff, setSortOrderAffiliation] = useState('asc');
  const itemsPerPage = 15; // Jumlah item per halaman

  useEffect( () => {
    getLecturers();
  },[]);

  const getLecturers = async () => {
    let lecturers = await LecturerService.getAll();
    lecturers = lecturers.data.data
    setLecturers(lecturers || []);
    setTotalLecturer(lecturers.length)
  }

  // TODO :
  const deleteLecturer = async (id) => {
    await LecturerService.deleteLecturer(id);
    getLecturers();
  }

  // Filter data siswa berdasarkan pencarian
  const filteredLecturer = lecturers.filter((student) => {
    return (
      student.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.affiliation.toLowerCase().includes(searchTerm.toLowerCase()) || 
      student.academic_position.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const sortByAffiliation = () => {
    const sortedStudentsAff = [...lecturers].sort((a, b) => {
      if (sortOrderAff === 'asc') {
        return a.affiliation.localeCompare(b.affiliation);
      } 
      return b.affiliation.localeCompare(a.affiliation);
    });
    setLecturers(sortedStudentsAff);
    setSortOrderAffiliation(sortOrderAff === 'asc' ? 'desc' : 'asc'); // Toggle urutan sortir
  };
  
  // Hitung total halaman
  const totalPages = Math.ceil(filteredLecturer.length / itemsPerPage);

  // Ambil data yang sesuai dengan halaman saat ini
  const currentLecturer = filteredLecturer.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Fungsi untuk pindah halaman
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (lecturers.length === 0) {
    return(
      <div className='container-fluid'>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Lecturer Data</h1>
          <a href="/lecturer/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
            <i className="fas fa-fw fa-user-tag"></i> <span>Add New Lecturer</span>
          </a>
        </div>
        <div className="text-center my-5">
          {/* Spinner animasi untuk menampilkan loading */}
          <Spinner animation="border" role="status" className="mb-3">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p className='fs-6 fw-semibold'>if reload takes too long please go back, data is either empty or still in processing</p>
        </div>
      </div>
    )    
  } 

  return(
      <div className='container-fluid'>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Lecturer Data</h1>
          <a href="/lecturer/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
            <i className="fas fa-fw fa-user-tag"></i> <span>Add New Lecturer</span>
          </a>
        </div>
        {/* total student */}
      <Box 
          sx={{
              backgroundColor: '#f0f8ff', 
              borderRadius: 2, 
              padding: 2, 
              textAlign: 'center',
              boxShadow: 1
          }}
      >
          <Typography variant="h6" color="primary">
              Total Lecturers
          </Typography>
          <Typography variant="h4" fontWeight="bold" color="secondary">
              {totalLecturer}
          </Typography>
      </Box>

      {/* Input Pencarian */}
      <div className='mb-3'>
          <input
            type='text'
            className='form-control'
            placeholder='Search lecturers...'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
      </div>

        <table className='table table-sm'>
          <thead>
            <tr>
              <th>No</th>
            <th>National ID (NIDN)</th>
            <th>Full Name</th>
            <th onClick={sortByAffiliation} style={{ cursor: 'pointer' }}>
              Affiliation {sortOrderAff === 'asc' ? <AiIcons.AiOutlineArrowUp /> : <AiIcons.AiOutlineArrowDown />}
            </th>   
            <th>Academic Position</th>
            <th>Study Program</th>   
            <th>Email</th>                 
            <th>Actions</th>
            </tr>
          </thead>
          <tbody className='table-group-divider'>
            { currentLecturer.map((lecturer, index) => (
                <tr key={lecturer.mdl_id}>
                  <td>{index + 1}</td>
                    <td>{lecturer.username}</td>
                    <td>
                      { 
                        `${lecturer.firstname} ${lecturer.middlename} ${lecturer.lastname}`.length > 40
                        ? `${lecturer.firstname} ${lecturer.middlename} ${lecturer.lastname}`.substring(0, 45) + "..." 
                        : `${lecturer.firstname} ${lecturer.middlename} ${lecturer.lastname}`
                      }
                    </td>
                    <td>{lecturer.affiliation}</td>   
                    <td>{lecturer.academic_position}</td>  
                    <td>{lecturer.study_program}</td>   
                    <td>{lecturer.email}</td>                
                    <td>
                        <Link title='Detail' to={`/lecturer/`+lecturer.mdl_id}><BiIcons.BiDetail size={18} /></Link>&nbsp;&nbsp;&nbsp;
                        <Link title='Edit' to={`/lecturer/edit/`+lecturer.mdl_id}><AiIcons.AiFillEdit size={18} /></Link>&nbsp;&nbsp;&nbsp;
                        {/* <Link title='Delete' onClick={()=>deleteLecturer(lecturer.id)}><AiIcons.AiTwotoneDelete size={18} /></Link> */}
                    </td>
                </tr>
            )) }
          </tbody>
        </table>
        {/* Pagination Controls */}
        <nav>
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
            Previous
          </button>
        </li>
        {[...Array(totalPages).keys()].map((number) => (
          <li key={number + 1} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(number + 1)}>
              {number + 1}
            </button>
          </li>
        ))}
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
            Next
          </button>
        </li>
      </ul>
    </nav>
    </div>      
  )
}

export default LecturerList