/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';

import OfferedcourseService from './OfferedcourseService';
import LecturerService from '../lecturer/LecturerService';
import { useNavigate } from 'react-router-dom';

const OfferedcourseList = () => {
  const [students, setData] = useState([]);
  const [lecturers, setLecturers] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedUserID, setselectedUserID] = useState();
  const [selectedCourseID, setselectedCourseID] = useState();

  const navigate = useNavigate();

  useEffect( () => {    
    getCourseoffered()
    getLecturers();
  },[]);

  const getLecturers = async () => {
    let lecturers = await LecturerService.getAll();
    lecturers = lecturers.data.data
    setLecturers(lecturers || []);
  }

  const getCourseoffered = async () => {
    const response = await OfferedcourseService.getEnrolledCourse();
    const students = response.data.data || [];
    const filteredStudents = students.filter(student => student.role_id === 3);
    setData(filteredStudents || []);
  }

  const getUsernameByUserId = (userId) => {
    const lecturer = lecturers.find((lecturer) => lecturer.mdl_id === userId);
    return lecturer ? lecturer.username : "Username not found"; // Kembalikan username atau pesan jika tidak ditemukan
  };

  const unenrolLecturer = async (course_id, user_id) => {
    try {
      const responseUnenroll = await OfferedcourseService.unEnrollCourse(course_id, user_id);
  
      if (responseUnenroll.data.status_code === 201) {
        alert("successfully unenroll lecturer")
        navigate("/"); // Navigasi ke halaman lain jika berhasil
      } else {
        console.error("Unenroll failed:", responseUnenroll.data.message || "Unknown error occurred");
        alert("Failed to unenroll lecturer: " + (responseUnenroll.data.message || "Unknown error occurred"));
        navigate("/");
      }
    } catch (error) {
      // Tangani error jaringan atau lainnya
      console.error("Error during unenroll:", error);
      alert("An error occurred while trying to unenroll the lecturer. Please try again later.");
    }
  }

  const getLecturerName = (userId) => {
    const lecturer = lecturers.find(lect => lect.mdl_id === userId);
    if (lecturer) {
      return `${lecturer.firstname} ${lecturer.middlename} ${lecturer.lastname}`.replace(/\s+/g, ' ').trim();
    }
    return '-'; // Jika dosen tidak ditemukan, tampilkan '-'
  };


  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Jumlah item per halaman

  // Hitung total halaman
  const totalPages = Math.ceil(students.length / itemsPerPage);

  // Ambil data yang sesuai dengan halaman saat ini
  const currentOffCourse = students.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Fungsi untuk pindah halaman
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleUnenroll = (course_id, user_id) => {
    setselectedUserID(user_id);
    setselectedCourseID(course_id);
    setShowConfirm(true);
  };

  const handleConfirmUnenroll = () => {
    if (selectedCourseID && selectedUserID)  {
      unenrolLecturer(selectedCourseID, selectedUserID);
    }
    setShowConfirm(false);
  };

  const handleCancelUnenrolle = () => {
    setShowConfirm(false);
    setselectedCourseID(null);
    setselectedUserID(null);
  };

  // inline style css
  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  const modalContentStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '4px',
    width: '300px',
    textAlign: 'center',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
  };

  if (students.length === 0) {
    return(
      <div className='container-fluid'>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Student Data</h1>
          <a href="/student/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
            <i className="fas fa-fw fa-user"></i> <span>Add New Student</span>
          </a>
        </div>
        <div><p className='fs-6 fw-semibold'>The student data is empty. Please add some new students !!</p></div>
      </div>
    )    
  } else {
    return(
      <div className='container-fluid'>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Course Lecturers</h1>
          <a href="/offeredcourse/add" className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm">
            <i className="fas fa-fw fa-user"></i> <span>Enroll Lecturer to Course</span>
          </a>
        </div>

        <table className='table table-sm'>
          <thead>
            <tr>
            <th>ID</th>
            <th>Course</th>
            <th>Lecturer</th>
            <th>Actions</th> 
            </tr>
          </thead>

          <tbody className='table-group-divider'>
            { currentOffCourse.filter(course => course.format !== 'site').map((course, index) => (
                <tr key={course.id}>
                    <td>{index + 1}</td>
                    <td>{course.course_id} - {course.course_name}</td>
                    <td>{getUsernameByUserId(course.user_id)} - {getLecturerName(course.user_id)}</td>
                    {/* <td>
                    {course.is_active ? (
                      <AiIcons.AiOutlineCheck size={20} color="green" />
                    ) : (
                      <AiIcons.AiOutlineClose size={20} color="red" />
                    )}
                    </td> */}
                    <td> 
                        <button className='btn btn-primary btn-sm' onClick={ () => handleUnenroll(course.course_id, course.user_id) }>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-repeat" viewBox="0 0 16 16">
                            <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                            <path fillRule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
                          </svg>
                          Un-enroll 
                        </button>                        
                    </td>
              </tr>
            )) }
          </tbody>
        </table>

        {/* Modal konfirmasi */}
        {showConfirm && (
          <div style={modalStyle}>
            <div style={modalContentStyle}>
              <p>Are you sure you want to enroll this lecturer?</p>
              <button className="btn btn-danger" onClick={handleConfirmUnenroll}>Confirm</button>&nbsp;&nbsp;
              <button className="btn btn-secondary" onClick={handleCancelUnenrolle}>Cancel</button>
            </div>
          </div>
        )}

          {/* Pagination Controls */}
        <nav>
          <ul className="pagination">
            {[...Array(totalPages).keys()].map((number) => (
              <li key={number + 1} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(number + 1)}>
                  {number + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>      
    )
  }
}

export default OfferedcourseList