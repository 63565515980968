import HttpCommon from "../../HttpCommon";
import HttpProdi from "../../HttpSmartProdi";

const clientId = 'frontend'; // Replace with your actual client_id
const apiKey = 'a60ab88e2afedd6fdf637cdc4be4c12205eb8fe644726c0b9f121b3f0a1044e9';     // Replace with your actual x-api-key

class CourseService {
    getAll = () =>{
        try {
            return HttpProdi.get("/course");
        } catch (error) {
            console.log(error)
            return error
        }
    }

    getCourseGrade = (course_id, user_id) =>{
        try {
            return HttpProdi.post("/grade/course",
            {
                course_id: parseInt(course_id, 10),
                user_id: parseInt(user_id, 10)
            });
        } catch (error) {
            console.log(error)
            return error
        }
    }

    getReportGrade = (course_id, user_id) =>{
        try {
            return HttpProdi.post("/report/grade/course/pdf",
            {
                course_id: parseInt(course_id, 10),
                user_id: parseInt(user_id, 10)
            },{ responseType: 'blob' });
        } catch (error) {
            console.log(error)
            return error
        }
    }

    getReportAttendance = (course_id, session_id) => {
        let courseid = parseInt(course_id, 10);
        try {
            console.log(course_id, session_id);
            return HttpProdi.post(
                `/report/attendance/course/${courseid}/pdf`,
                {}, // body kosong karena hanya query parameter yang dikirim
                {
                    params: {
                        session_id: parseInt(session_id, 10)
                    },
                    responseType: 'blob' // memastikan respons diterima sebagai blob
                }
            );
        } catch (error) {
            console.log(error);
            return error;
        }
    };

    getCourseAttendance = (course_id, user_id) => {
        try {
            return HttpProdi.get(`/attendance/course/${course_id}`, {
                params: {
                    user_id: parseInt(user_id, 10)
                }
            });
        } catch (error) {
            console.log(error);
            return error;
        }
    }

    getCourseEnrollment = (course_id) => {
        try {
            return HttpProdi.post(`/enrolleduser/${course_id}`);
        } catch (error) {
            console.log(error);
            return error;
        }
    }

    getCourseById (courseId) {
        try {
            return HttpProdi.get("/course/"+courseId);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    addCourse = async(course) => {
        try {
            const courseArray = [course];
            return await HttpProdi.post("/course", courseArray);
        } catch (error) {
            console.log(error)
            return error
        }
    }

    putCourse = async(course, courseId) => {
        try {
            return await HttpProdi.put("/course/"+courseId,  course);
        } catch (error) {
            console.log(error)
            return error
        }
    }
    
    deleteCourse = (courseId) => {
        try {
            return HttpProdi.delete("/course/"+courseId, {
                headers: {
                  "X-Api-Key": apiKey, 
                  "X-Client_id": 'frontend', 
                  "Content-type": "application/json"
                }
              }
            );
        } catch (error) {
            console.log(error)
        }
    }
}

export default new CourseService();