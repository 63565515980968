/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CourseService from './CourseService';
import StudentService from '../student/StudentService';
import { courseFormatParse} from '../../utils/utils';
import * as AiIcons from 'react-icons/ai';

import OfferedcourseService from '../offeredcourse/OfferedcourseService';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Breadcrumb, Tab, Tabs, Badge  } from 'react-bootstrap';
import CPLService from '../competency/CplService';
import '../../App.css';
import Modal from 'react-bootstrap/Modal';

const ViewCourse = () => {
  const [name_id, setName_id] = useState('');
  const [course_format, setCourseFormat] = useState('');
  const [credit, setCredit] = useState('');
  const [semester, setSemester] = useState('');
  const [visible, setVisible] = useState('');
  // const [curriculum_year, setCurriculum_year] = useState('');
  const [description, setDescription] = useState('');
  const [courseStartTime, setCourseStartTime] = useState('');
  const [courseEndTime, setCourseEndTime] = useState('');
  const [showConfirm, setShowConfirm] = useState(false); // Popup state
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [categID, setcategID] = useState('');
  const [categName, setcategName] = useState('');
  const [gradeComponents, setGradeComponents] = useState([]);
  const [gradeid, setGradeid] = useState();
  const [coursecode, setcoursecode] = useState();

  const [cpmk, setcpmk] = useState([]); // Competencies
  const [enrolledLecturer, setEnrolledLecturer] = useState([]);

  const handleClose = () => setLgShow(false);
  const reload=()=>window.location.reload();
  
  const [isgetCPL, setisgetCPL] = useState(false);

  const [cplData, setcplData] = useState([]);

  const [cplID  , setcplID] = useState();
  const [cpmkID  , setcpmkID] = useState();
  const [cpmkByCPL, setcpmkByCPL] = useState([]); // Competencies

  // flag show modal
  const [lgShow, setLgShow] = useState(false);
  const handleShow = () => {
    setisgetCPL(true)
    setLgShow(true);
  }

  const {id} = useParams();
  const navigate = useNavigate();
  useEffect( () => {
    getCourseById();
  },[]);

  useEffect(() => {
    getGradeComponents();
    getCPMKListByCourseID();
    getEnrollments();
    if (categID) {
      getCategoryByID();
    }

    if (isgetCPL) {
      getCPLList();
      setisgetCPL(false)
    }

  }, [categID, isgetCPL]); // This effect depends on categID

  const getCPLList = async () => {
    const cplList = await CPLService.getCPLList();
    setcplData(cplList.data.data || []);
  }

  const getGradeComponents = async () => {
    let resComp = await StudentService.getGrade();
    resComp = resComp.data.data
    setGradeComponents(resComp );
  }

  const getEnrollments = async () => {
    let coursesGrades = await CourseService.getCourseEnrollment(id);
    coursesGrades = coursesGrades.data.data 

    // Filter untuk lecturer dengan roleid 3
    setEnrolledLecturer(coursesGrades.filter((enrollment) => 
      enrollment.roles.some(role => role.roleid === 3)
    ));
   
  }

  const getCPMKListByCourseID = async () => {
    const students = await CPLService.getCPMKbyCourseID(id); // Assuming `id` is course id
    let res = students.data.data;
    const competencies = res.map(item => item.competency);
    setcpmk(competencies);
  };

  const getCourseById = async () => {
    let res = await CourseService.getCourseById(id);
    res = res.data

    setName_id(res.data.name);
    setCourseFormat(res.data.format);
    setCredit(res.data.course_credit);
    setSemester(res.data.course_semester);
    // setCurriculum_year(res.data.curriculum_year);
    setDescription(res.data.summary);
    setcategID(res.data.category_id);
    setVisible(res.data.visible);
    setGradeid(res.data.grade_component_id)
    setcoursecode(res.data.course_id_number)

    const courseStartDate = res.data.course_start_date;// Convert timestamp ke millisecond dan buat Date object
    const date = new Date(courseStartDate * 1000);
    
    const courseEndDate = res.data.course_end_date;
    const dateEnd = new Date(courseEndDate * 1000);
    
    // Menggunakan metode toLocaleString untuk mengonversi ke zona waktu Indonesia (WIB) -
    const options = { timeZone: 'Asia/Jakarta', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit',  };
    const indonesiaTime = date.toLocaleString('id-ID', options);
    setCourseStartTime(indonesiaTime)
    const indonesiaTimeEnd = dateEnd.toLocaleString('id-ID', options);
    setCourseEndTime(indonesiaTimeEnd)
  }

  const getCategoryByID = async () => {
    try {
      let res = await StudentService.getCategoryById(categID);
      res = res.data;
      setcategName(res.data.name)
    } catch (error) {
      console.error("Error fetching category:", error);
    }
  }

  const getGradeComponentNameById = ( id, data) => {
    const item = data.find(entry => entry.id === id);
    return item ? item.name : ``;
  };

  const deleteCourse = async (id) => {
    await CourseService.deleteCourse(id);
    setShowConfirm(false); // Close popup
    navigate('/course');
  }

  const unenrolLecturer = async (course_id, user_id) => {
    try {
      const responseUnenroll = await OfferedcourseService.unEnrollCourse(course_id, user_id);
      if (responseUnenroll.data.status_code === 201) {
        alert("successfully unenroll lecturer")
        getEnrollments()
        // navigate("/"); // Navigasi ke halaman lain jika berhasil
      } else {
        console.error("Unenroll failed:", responseUnenroll.data.message || "Unknown error occurred");
        alert("Failed to unenroll lecturer: " + (responseUnenroll.data.message || "Unknown error occurred"));
        navigate("/");
      }
    } catch (error) {
      // Tangani error jaringan atau lainnya
      console.error("Error during unenroll:", error);
      alert("An error occurred while trying to unenroll the lecturer. Please try again later.");
    }
  }

  const handleConfirmDelete = () => {
    if (selectedCourseId) {
      deleteCourse(selectedCourseId);
    }
    setShowConfirm(false);
  };

  const handleDeleteClick = (id) => {
    setSelectedCourseId(id);
    setShowConfirm(true); // Show popup when delete button is clicked
  };

  const removeCPMK = async (cpmk_id) => {
    try {
      const response = await CPLService.removeCPMKtoCourse({
        'competency_id' : parseInt(cpmk_id, 10), 
        'course_id':parseInt(id, 10), 
      });
  
      if (!response.data.error) {
        alert("Success remove cpmk from course!");
        setLgShow(false);
        getCPMKListByCourseID();
      } else {
        console.error("Error in response:", response.data.error);
        alert("Failed to assign lecturer: " + (response.data.error.message || "Unknown error occurred"));
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          console.error("Bad Request:", error.response.data);
          alert("Invalid input data. Please check and try again.");
        } else if (error.response.status === 500) {
          console.error("Server Error:", error.response.data);
          alert(error.response.data.message);
        } else {
          console.error("Unexpected Error:", error.response.data);
          alert("An unexpected error occurred. Please try again.");
        }
      } else {
        console.error("Network Error:", error);
        alert.error("Network error. Please check your internet connection and try again.");
      }
    }

  }

  const handleRemoveCPMK = (cpmk_id) => {
    removeCPMK(cpmk_id)
  };

  const handleCancelDelete = () => {
    setShowConfirm(false); // Close popup without deleting
    setSelectedCourseId(null);
  };

  const getCPMKList = async (cpl_id) => {
    const students = await CPLService.getCPMKbyCPLID(cpl_id);
    let res = students?.data?.data
    setcpmkByCPL(res || []);
  }

  const saveNewCPMk = async (e) => {
    e.preventDefault();
    try {
      const response = await CPLService.addCPMKtoCourse({
        competency_id: parseInt(cpmkID, 10),
        course_id : parseInt(id, 10),
      });
  
      // Jika respons tidak mengandung error, lanjutkan ke navigasi
      if (!response.data.error) {
        alert("Success add cpmk to course!");
        setLgShow(false);
        setTimeout(() => navigate(`/course/${id}`), 1000);
      } else {
        // Jika ada error di data respons
        console.error("Error in response:", response.data.error);
        alert("Failed to assign lecturer: " + (response.data.error.message || "Unknown error occurred"));
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          console.error("Bad Request:", error.response.data);
          alert("Invalid input data. Please check and try again.");
        } else if (error.response.status === 500) {
          console.error("Server Error:", error.response.data);
          alert(error.response.data.message);
        } else {
          console.error("Unexpected Error:", error.response.data);
          alert("An unexpected error occurred. Please try again.");
        }
      } else {
        console.error("Network Error:", error);
        alert.error("Network error. Please check your internet connection and try again.");
      }
    }
    }


  // Inline styles for popup modal
  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  const modalContentStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '4px',
    textAlign: 'center',
    width: '300px',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
  };
  
  return (
    <div className='container-fluid'>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/course'>Courses Data</Breadcrumb.Item>
          <Breadcrumb.Item active>Course Detail</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Container>
      <Row>
        <Col xs={6}>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Course Code :</b></Col>
            <Col xs={8}>{coursecode}</Col>
          </Row>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Course Name :</b></Col>
            <Col xs={8}>{name_id}</Col>
          </Row>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Course Description :</b></Col>
            <Col xs={8}>{description}</Col>
          </Row>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Credit :</b></Col>
            <Col xs={8}>{credit}</Col>
          </Row>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Semester :</b></Col>
            <Col xs={8}>{semester}</Col>
          </Row>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Status :</b></Col>
            <Col xs={8}>{visible ? (
                <Badge bg="success">Active</Badge>  // Badge Hijau untuk Active
              ) : (
                <Badge bg="danger">Inactive</Badge> // Badge Merah untuk Inactive
              )}</Col>
          </Row>
        </Col>

        <Col xs={6}>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Course Format :</b></Col>
            <Col xs={8}>{courseFormatParse(course_format)}</Col>
          </Row>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Course Category :</b></Col>
            <Col xs={8}>{categName}</Col>
          </Row>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Grade Component :</b></Col>
            <Col xs={8}>{getGradeComponentNameById(gradeid, gradeComponents)}</Col>
          </Row>

          {/* <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Curriculum :</b></Col>
            <Col xs={8}>{curriculum_year}</Col>
          </Row> */}
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>Start Time :</b></Col>
            <Col xs={8}>{courseStartTime}</Col>
          </Row>
          <Row>
            <Col xs={4} className='align-text-top' align='right'><b>End Time :</b></Col>
            <Col xs={8}>{courseEndTime}</Col>
          </Row>
        </Col>

      </Row>

        <Row><Col>&nbsp;</Col></Row>
        <Row className="d-flex justify-content-center">
          <Col xs="auto">
            <Link to={`/course`} className='btn btn-outline-dark btn-sm'><AiIcons.AiOutlineRollback size={18} />Back</Link>
            &nbsp;&nbsp;

            <Link to={`/course/edit/`+id} className='btn btn-outline-primary btn-sm'><AiIcons.AiFillEdit size={18} />Edit</Link>
            &nbsp;&nbsp;

            <Link title='Delete' className='btn btn-outline-danger btn-sm' onClick={() => handleDeleteClick(id)}>
              <AiIcons.AiTwotoneDelete size={18} /> Delete
            </Link>
          </Col>
        </Row>


        {/* Tabs for Competencies and Teachers */}
        <Tabs defaultActiveKey="competencies" id="course-details-tabs" style={{ color: 'orange' }}>
          <Tab eventKey="competencies" title="Competencies" style={{ color: 'orange' }}>
            {/* Content displayed only when Competencies tab is active */}
            <br></br>
            <ul style={{ color: 'black' }}>
              {cpmk.map((val,idx) => (
                <li key={val.id}>
                  <strong>{val.shortname} ({val.idnumber}):</strong> {val.description}
                  <div><br></br></div>
                  <td>
                      <Link title='Delete' className='btn btn-outline-danger btn-sm' 
                      onClick={() => handleRemoveCPMK(val.id)}>
                      <AiIcons.AiTwotoneDelete size={18} /></Link>
                  </td>
                </li>
                
              ))}
            </ul>

            {/* Add CPMK Button */}
          <div className="d-flex justify-content-start mt-3">
          <button
            onClick={() => handleShow()}
            className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
          >
            <i className="fas fa-fw fa-plus"></i> <span>Add new CPMK</span>
          </button>
          </div>
          </Tab>
          
          <Tab eventKey="teachers" title="Lecturers">
          <br></br>
            {/* Content displayed only when Teachers tab is active */}
            <ul style={{ color: 'black' }}>
              {enrolledLecturer.map((teacher) => (
                <li key={teacher.id}>
                  
                  ({teacher.username}) {teacher.fullname} 
                  <div><br></br></div>

                  <td>
                      <Link title='Remove' className='btn btn-outline-danger btn-sm' 
                      onClick={() => unenrolLecturer(id, teacher.id, )}>
                      <AiIcons.AiTwotoneDelete size={18} /></Link>
                  </td>
                </li>
              ))}
            </ul>
          </Tab>
        </Tabs>        
      </Container>

      <Modal
      size="lg"
      show={lgShow}
      onHide={() => setLgShow(false)}
      onExit={reload}
      aria-labelledby="example-modal-sizes-title-lg"
    >        
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Add CPMK to Course
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={saveNewCPMk} 
      >
      <Modal.Body>      
          <div className="row mb-3">
            <label className="col-sm-3 col-form-label">CPL</label>
            {/* <div className='col-sm-5'>{name}</div>           */}
            <div className='col-sm-3'>
            <select className='form-select' value={ cplID } required 
            
            onChange={(e) => {
              const selectedCplId = parseInt(e.target.value, 10);
              setcplID(selectedCplId);
              getCPMKList(selectedCplId); // Call getCPMKList with the selected CPL ID
            }}>
              <option>-Select CPL-</option>
              {cplData?.map((category, index) => {
                return (
                  <option key={index} value={category.id}>
                  ({category.idnumber}) {category.shortname}
                  </option>
                )
              })}              
            </select>
          </div>
          </div>

          {cplID && (
            <div className="row mb-3">
              <label className="col-sm-3 col-form-label">CPMK</label>
              <div className='col-sm-3'>
            <select className='form-select' value={ cpmkID } required 
            
            onChange={(e) => {
              const selectedCplId = parseInt(e.target.value, 10);
              setcpmkID(selectedCplId);
            }}>
              <option>-Select CPMK-</option>
              {cpmkByCPL?.map((category, index) => {
                return (
                  <option key={index} value={category.id}>
                  ({category.idnumber}) {category.shortname}
                  </option>
                )
              })}              
            </select>
          </div>
            </div>
          )}

          {/* <div className="row mb-3">
            <label className="col-sm-3 col-form-label">CPMK Code</label>
            <div className='col-sm-5'><input 
              type="text"
              className="form-control"
              value={ cpmkcode } 
              onChange={ (e) => setcpmkcode(e.target.value) }
              placeholder="CPMK code"
              required/>
            </div>              
          </div> */}


      </Modal.Body>

      <Modal.Footer>
        <div className="field">
          <Link className='btn btn-outline-danger btn-sm' onClick={handleClose}><AiIcons.AiOutlineRollback size={18} />Cancel</Link>
          &nbsp;&nbsp;
          <Link title='Save' className='btn btn-outline-success btn-sm' onClick={saveNewCPMk}><AiIcons.AiFillSave size={18} />Save</Link>
        </div>
      </Modal.Footer>
      </form>
    </Modal>
      
      {/* Modal konfirmasi */}
      {showConfirm && (
        <div style={modalStyle}>
          <div style={modalContentStyle}>
            <p>Are you sure you want to delete this course?</p>
            <button className="btn btn-danger" onClick={handleConfirmDelete}>Confirm</button>&nbsp;&nbsp;
            <button className="btn btn-secondary" onClick={handleCancelDelete}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  )
}

export default ViewCourse
