import React, {useState, useEffect} from 'react';
import { Link , useParams} from 'react-router-dom';
import CourseService from '../course/CourseService';

import * as BiIcons from 'react-icons/bi';
import { Box, Typography } from '@mui/material';
import { Breadcrumb} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {  Spinner } from 'react-bootstrap';


export const GradeList = () => {
  const [totalCourse, setTotalCourse] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 10; // Jumlah item per halaman
  const [grades, setGrades] = useState([]);

  const {id} = useParams();

  useEffect( () => {
    getGrades();
  },[]);

  const getGrades = async () => {
    let coursesGrades = await CourseService.getCourseGrade(id, 0);
    coursesGrades = coursesGrades.data.data 
    setGrades(coursesGrades || []);
    // setTotalCourse(courses.length)
  }

  // Hitung total halaman
  const totalPages = Math.ceil(grades.length / itemsPerPage);

  // Ambil data yang sesuai dengan halaman saat ini
  const currentCourses = grades.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Fungsi untuk pindah halaman
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDownloadGrade = async () => {
    try {
      const response = await getReportGrades();

      // Pastikan response adalah Blob
      if (!response || !(response.data instanceof Blob)) {
        console.warn("Received data is not a Blob.");
        alert("The downloaded data is not in the correct format. Please check the server.");
        return;
      }

      if (response.data.size === 0) {
          console.warn("Received an empty file.");
          alert("The downloaded file is empty. Please check the server.");
          return;
      }

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'GradeReport.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

    } catch (error) {
        console.error("Failed to download the report:", error);
        alert("Failed to download the report. Please try again.");
    }
  };

  const getReportGrades = async () => {
    try {
      const response = await CourseService.getReportGrade(id, 0);
      return response;
    } catch (error) {
      console.log("Error fetching report grade:", error);
      throw error;
    }
  };

  // inline style css
  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  const modalContentStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '4px',
    width: '300px',
    textAlign: 'center',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
  };

  if (grades.length === 0) {
    return (
      <div className='container-fluid'>
        <Breadcrumb>
          <Breadcrumb.Item href='/grade'>Grade</Breadcrumb.Item>
          <Breadcrumb.Item active>Grade List</Breadcrumb.Item>
        </Breadcrumb>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Courses Grade</h1>
        </div>
        <div className="text-center my-5">
          {/* Spinner animasi untuk menampilkan loading */}
          <Spinner animation="border" role="status" className="mb-3">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <p className='fs-6 fw-semibold'>if reload takes too long please go back, data is either empty or still in processing</p>
        </div>
      </div>
    );
  }

  return (
      <div className='container-fluid'>
        {/* Tombol download PDF */}

        <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <Breadcrumb>
          <Breadcrumb.Item href='/grade'>Grade</Breadcrumb.Item>
          <Breadcrumb.Item active>Grade List</Breadcrumb.Item>
        </Breadcrumb>
        <div className="text-right">
          <button onClick={handleDownloadGrade} className="btn btn-primary mt-4">
          <i className="fas fa-download mr-2"></i> {/* Ikon download */}
            Download PDF
          </button>
        </div>

      </div>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
      </div>
      <Box 
          sx={{
              backgroundColor: '#f0f8ff', 
              borderRadius: 2, 
              padding: 2, 
              textAlign: 'center',
              boxShadow: 1
          }}
      >
          <Typography variant="h6" color="primary">
              Course Grade List
          </Typography>
          <Typography variant="h4" fontWeight="bold" color="secondary">
              {totalCourse}
          </Typography>
      </Box>

      {/* Input Pencarian */}
      <div className='mb-3'>
          <input
            type='text'
            className='form-control'
            placeholder='Search courses...'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
      </div>

      <table className='table table-sm'>
      <thead>
        <tr>
          <th>No</th>
          <th>Course Name</th>
          <th>Student ID</th>
          <th>Student Name</th>
          <th>Total Average Grade Component</th>
          <th>Total Average Grade</th>
          <th>Detail</th>
        </tr>
      </thead>

      <tbody className='table-group-divider'>
        {grades.map((grade, index) => {
          // Menghitung total component weight dan average component grade untuk setiap grade
          const totalComponentWeight = grade.grade_avg.reduce((sum, section) => sum + section.component, 0);
          const totalAverageComponentGrade = grade.grade_avg.reduce((sum, section) => sum + section.average_component_grade, 0);
          const totalAvgGrade = totalComponentWeight > 0 ? (totalAverageComponentGrade / totalComponentWeight).toFixed(2) : '-';

          return (
            <tr key={grade.courseid}>
              <td>{index + 1}</td>
              <td>{grade.course_name}</td>
              <td>{grade.userid}</td>
              <td>{grade.userfullname}</td>
              <td>{totalAverageComponentGrade.toFixed(2)}</td>
              <td>{totalAvgGrade}</td>
              <td>
                <Link title='Detail' to={`/grade/${grade.courseid}/${grade.userid}`}>
                  <BiIcons.BiDetail size={18} />
                </Link>&nbsp;&nbsp;&nbsp;
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>

    {/* Pagination Controls */}
    <nav>
      <ul className="pagination">
        {[...Array(totalPages).keys()].map((number) => (
          <li key={number + 1} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
            <button className="page-link" onClick={() => handlePageChange(number + 1)}>
              {number + 1}
            </button>
          </li>
        ))}
      </ul>
    </nav>

    </div>
  )
}
